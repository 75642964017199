import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Dropdown, Modal } from 'react-bootstrap';
import { Pagination, Search } from '../Table';
import Loader from '../Pages/Loader';
import CopyRight from '../Pages/CopyRight';
import moment from 'moment';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';

const Earning = () => {

  const navigate = useNavigate();
  const [entity, setEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setlimit] = useState(10);
  const [disable, setDisable] = useState(true);
  const [modalData, setModalData] = useState({
    remark: "",
    oRemark: ""
  });
  const [show, setShow] = useState(false);
  const [month, setMonth] = useState('');

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }
    if (disable || (month !== undefined)) {
      setEntity([]);
      getEarning();
    }
  }, [disable, navigate, month]);

  const commentsData = useMemo(() => {
    let computedComments = entity;
    if (search) {
      computedComments = computedComments.filter(
        (earn) =>
          earn?.employee?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          // earn?.employee?.adhar_no?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          earn?.employee?.card_no?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          earn?.employee?.employee_id?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);

    // if (month !== '') {
    //   computedComments = computedComments.filter((earn) => earn.month === parseInt(month));
    // }

    return computedComments?.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, limit, entity]);


  const getEarning = () => {
    const myurl = `${PAY_URL}/user/get-admin-earning?month=${month}&firm=${localStorage.getItem('PAY_USER_FIRM_ID')}&year=${localStorage.getItem('PAY_USER_YEAR_ID')}`;
    axios({
      method: "get",
      url: myurl,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    })
      .then(async (response) => {
        // console?.log("@@", response?.data);
        if (response?.data?.success) {
          const data = response?.data?.data
          const filteredData = data?.filter(e =>
            e.firm_id?._id === localStorage.getItem('PAY_USER_FIRM_ID') &&
            e.year_id?._id === localStorage.getItem('PAY_USER_YEAR_ID')
          );
          setEntity(filteredData);
          setDisable(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        console?.log("Errors", error);
      });
  }

  const handleDelete = (id, title) => {
    Swal.fire({
      title: `Are you sure want to delete ${title}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        const myurl = `${PAY_URL}/user/delete-earning`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("id", id);
        axios({
          method: "delete",
          url: myurl,
          data: bodyFormData,
          headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then((response) => {
          // console.log(response.data, 'DEL')
          if (response.data.success === true) {
            toast.success(response?.data?.message);
            setDisable(true);
          } else {
            toast.error(response?.data?.message);
          }
        }).catch((error) => {
          toast.error(error?.response?.data?.message);
          console?.log("Errors", error);
        });
      }
    });
  }

  const handleRefresh = () => {
    setDisable(true);
    setMonth('');
    setCurrentPage(1);
    setSearch('');
  }

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setMonth(selectedMonth);
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = (info) => {
    setModalData({
      remark: info?.remark,
      oRemark: info?.other_remark
    })
    setShow(true);
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title user-title">Earning Management</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Earning Management</li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <Link to='/user/manage-earning' className="btn btn-success"><i className="fa-solid fa-plus"></i> Add Earning</Link>
              </div>
            </div>
          </div>

          <div className="row filter-row justify-content-end">
            <div className='col-md-4 col-sm-12'>
              <div className="row justify-content-start">
                <div className="col-md-12 mb-2">
                  <select className="form-select form-control" value={month} onChange={handleMonthChange}
                    aria-label="Default select example">
                    <option value=''>Select Month</option>
                    <option value='1'>January</option>
                    <option value='2'>February</option>
                    <option value='3'>March</option>
                    <option value='4'>April</option>
                    <option value='5'>May</option>
                    <option value='6'>June</option>
                    <option value='7'>July</option>
                    <option value='8'>August</option>
                    <option value='9'>September</option>
                    <option value='10'>October</option>
                    <option value='11'>November</option>
                    <option value='12'>December</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-8">
              <div className="row justify-content-end">
                <div className="col-md-12 mb-2">
                  <div className="justify-content-end d-flex align-center flex-wrap gap-2">
                    <div className="seletePage d-flex gap-2 align-items-center ">
                      <p className="mb-0">Rows per page:</p>
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic"
                            style={{
                              cursor: "auto",
                              backgroundColor: "white",
                              borderColor: "#d5dbe0",
                              paddingBottom: "3px",
                              paddingTop: "3px",
                            }}
                            className='form-control selectWidth'
                          >
                            {limit}&nbsp;
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {limit !== 10 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(10);
                                  }}
                                >
                                  10
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 20 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(20);
                                  }}
                                >
                                  20
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 30 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(30);
                                  }}
                                >
                                  30
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 50 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(50);
                                  }}
                                >
                                  50
                                </Dropdown.Item>
                              </>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="refreshPage">
                          <button className="btn btn-refresh" type='butotn' onClick={handleRefresh}>
                            <i className="fa-solid fa-arrows-rotate"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="tableSearch">
                      <form action="">
                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }} />
                        <span className="search">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {disable === false ? (
            <div className="card p-3 ">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0">
                      <thead>
                        <tr>
                          <th>Sr.</th>
                          <th>Employee</th>
                          <th>Id</th>
                          <th>Card Id</th>
                          <th>Aadhar Card</th>
                          <th>Department</th>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Remarks</th>
                          <th>Status</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commentsData?.map((elem, i) =>
                          <tr key={elem?._id}>
                            <td>{(currentPage - 1) * limit + i + 1}</td>
                            <td>{elem?.employee?.full_name}</td>
                            <td>{elem?.employee?.employee_id}</td>
                            <td>{elem?.employee?.card_no ? elem?.employee?.card_no : '-'}</td>
                            <td>{elem?.employee?.adhar_no}</td>
                            <td>{elem?.salary_id?.department?.name}</td>
                            <td>{elem?.type}</td>
                            <td>{elem?.amount}</td>
                            <td>{moment(elem?.date).format('YYYY-MM-DD')}</td>
                            <td><button type="button" onClick={() => handleShow(elem)} className="btn btn-outline-success">View</button></td>
                            <td>
                              {elem.status === true ? (
                                <span className="badge text-bg-user">True</span>
                              ) : (
                                <span className="badge text-bg-danger">False</span>
                              )}
                            </td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                <a href="#" className="action-icon dropdown-toggle"
                                  data-bs-toggle="dropdown" aria-expanded="false"><i
                                    className="material-icons">more_vert</i></a>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <button type='button' onClick={() => navigate('/user/manage-earning', { state: elem })} className="dropdown-item"><i
                                    className="fa-solid fa-pencil m-r-5"></i> Edit</button>
                                  <button type='button' className="dropdown-item" onClick={() => handleDelete(elem?._id, elem.employee?.full_name)}><i
                                    className="fa-regular fa-trash-can m-r-5"></i> Delete</button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}

                        {commentsData?.length === 0 ? (
                          <tr>
                            <td colspan="999">
                              <div className="no-table-data">
                                No Data Found!
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="row align-center mt-3 user-account">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                      <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                        aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                      <div className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate">
                        <Pagination
                          total={totalItems}
                          itemsPerPage={limit}
                          currentPage={currentPage}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : <Loader />}

          <CopyRight />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className='mb-4'>
            <h3 className='text-center'>Remark</h3>
            <div className='d-flex justify-content-center p-3'>
              <p>{modalData.remark === '' ? 'No remark added' : modalData.remark}</p>
            </div>
          </div>

          <div className=''>
            <h3 className='text-center'>Other Remark</h3>
            <div className='d-flex justify-content-center p-3'>
              <p>{modalData.oRemark === '' ? 'No other remark added' : modalData.oRemark}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Earning