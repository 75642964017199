import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';

const DeleteModal = ({ deleteModal, handleClose, title, getDailyAttendance }) => {

    const [modal, setModal] = useState({ date: '', password: '', showEye: true });
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);

    const handleChange = (e) => {
        setModal({ ...modal, [e.target.name]: e.target.value });
    }

    const handleDelete = () => {
        if (validation()) {
            Swal.fire({
                title: `Are you sure want to delete ${modal.date || ''}?`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    setDisable(true);
                    const myurl = `${PAY_URL}/user/delete-date-daily-attendance`;
                    var bodyFormData = new URLSearchParams();
                    bodyFormData.append("password", modal.password);
                    bodyFormData.append("date", modal.date);
                    axios({
                        method: "delete",
                        url: myurl,
                        data: bodyFormData,
                        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
                    }).then((response) => {
                        if (response.data.success === true) {
                            toast.success(response?.data?.message);
                            getDailyAttendance();
                            setModal({ password: "", date: "", showEye: true });
                            handleClose();
                        } else {
                            toast.error(response?.data?.message);
                        }
                    }).catch((error) => {
                        toast.error(error?.response?.data?.message);
                        console?.log("Errors", error);
                    }).finally(() => {
                        setDisable(false);
                    })
                }
            })
        }
    }

    const validation = () => {
        let isValid = true;
        let err = {};
        if (!modal.date) {
            isValid = false;
            err['date_err'] = 'Please select a date';
        }
        if (!modal.password) {
            isValid = false;
            err['password_err'] = 'Please enter a password';
        }
        setError(err)
        return isValid
    }

    return (
        <Modal show={deleteModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <div className="input-group">
                            <span className="input-group-text">Date</span>
                            <input
                                type="date"
                                className="form-control position-relative"
                                value={modal.date}
                                name='date'
                                onChange={handleChange}
                            />
                        </div>
                        <div className='error'>{error?.date_err}</div>
                    </div>
                    <div className='col-12 mb-3'>
                        <div className="input-group">
                            <span className="input-group-text">Password</span>
                            <input
                                type={modal?.showEye ? "password" : "text"}
                                className="form-control position-relative"
                                value={modal.password}
                                name='password'
                                onChange={handleChange}
                            />
                            {modal?.showEye ? (
                                <div className='tranfer-eye' onClick={() => setModal({ ...modal, showEye: false })}><i className="fa-solid fa-eye" /></div>
                            ) : (
                                <div className='tranfer-eye' onClick={() => setModal({ ...modal, showEye: true })}><i className="fa-solid fa-eye-slash" /></div>
                            )}
                        </div>
                        <div className='error'>{error?.password_err}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-success' onClick={handleDelete} disabled={disable}>{disable ? "Processing..." : "Delete"}</button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal